// @flow
import ExtendableError from 'es6-error';

export class ApiError extends ExtendableError {
  statusCode: number;
  message: string;
  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}
export class InternetNotAvailableError extends ExtendableError {}

export const checkNetwork = (res: Response) => {
  const error = new InternetNotAvailableError();
  error.response = res;
  throw error;
};

export const handleResponse = (res: Response) => {
  if (res.ok) {
    const contentType = res.headers.get('Content-Type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return res.json();
    }
    return res;
  }
  return res.json().then(
    json => {
      const apiError = new ApiError(json.message, res.status);
      apiError.data = json;
      throw apiError;
    },
    error => {
      const apiError = new ApiError(res.statusText, res.status);
      apiError.data = error;
      throw apiError;
    }
  );
};

export const proFetch = {
  fetch: (url: string) => {
    return window
      .fetch(url, {
        method: 'GET',
        headers: new Headers({
          Accept: 'application/json',
        }),
      })
      .then(handleResponse, checkNetwork);
  },
  post: (url: string, body, headers) => {
    return window
      .fetch(url, {
        method: 'POST',
        headers:
          headers ||
          new Headers({
            Accept: 'application/json',
          }),
        body,
      })
      .then(handleResponse, checkNetwork);
  },
};
