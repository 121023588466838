// @flow
import * as React from 'react';
import {
  Button,
  Columns,
  Column,
  Stack,
  Image,
  Text,
  Heading,
} from '@locus-taxy/neo-ui';
import UserIdInput from './UserIdInput';
import ClientIdInput from './ClientIdInput';
import PasswordInput from '../fragment/PasswordInput';
import { FormButton, PersonnelErrorMessage } from 'components';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import { Hidden } from 'components/core';
import { useTranslation } from 'react-i18next';
import PersonnelPhotoFallback from 'images/personnel_fallback.svg';
import loginTestIds from 'components/login/loginTestIds.json';

const ProfileWrap = () => {
  const { personnel } = useLoginFormContext();
  const { t } = useTranslation();
  let header = t('loginHeader');
  if (personnel) {
    header = t('welcomeMessage');
  }
  return (
    <Stack space="large">
      <Heading level="3" fontWeight="300">
        {header}
      </Heading>
      <Profile />
    </Stack>
  );
};
const imageStyle = {
  borderRadius: '50%',
  objectFit: 'cover',
};
const Profile = () => {
  const { personnel, clientId, resetPersonnel } = useLoginFormContext();
  if (personnel) {
    const { name, photoUrl, clientId: personnelClientId } = personnel;
    const personnelPhotoUrl = photoUrl || PersonnelPhotoFallback;
    if (photoUrl) {
      imageStyle.backgroundColor = 'grey.200';
    }
    let displayClientId = personnelClientId;
    if (personnelClientId !== clientId) {
      displayClientId = `${personnelClientId} | ${clientId}`;
    }
    return (
      <Columns alignY="center" align="right" space="medium">
        <Image
          src={personnelPhotoUrl}
          maxWidth={40}
          maxHeight={40}
          sx={imageStyle}
          minHeight={40}
          minWidth={40}
        />
        <Column>
          <Stack>
            <Text>{name}</Text>
            <Text color="grey.300" fontWeight="600" fontSize={1}>
              {displayClientId}
            </Text>
          </Stack>
        </Column>

        <Column width="content">
          <Button
            data-test-id={loginTestIds.buttons.clearPersonnelData}
            color="grey.300" // overriding the neutral palette for icon
            appearance="icon"
            icon="cross"
            onClick={resetPersonnel}
          />
        </Column>
      </Columns>
    );
  }
  return <ProfileInput />;
};

const ProfileInput = () => {
  const {
    onFetchPersonnelData,
    personnelStatus,
    userId,
    clientId,
    defaultClientId,
  } = useLoginFormContext();
  const { t } = useTranslation();
  // disable if no user/client ID entered, or no user ID entered (when clientID in URL)
  const isClientIdInUrl = !!defaultClientId;
  const isUserIdOrClientIdFilled = !userId || !clientId;
  const isDisabled = isUserIdOrClientIdFilled || (!userId && isClientIdInUrl);
  const isLoading = personnelStatus === 'submitted';

  return (
    <Stack space="large" as="form">
      <ClientIdInput />
      <UserIdInput autoFocus />
      <Hidden>
        {/* Added to autocomplete username */}
        <input type="password" tabIndex="-1" />
      </Hidden>
      <PersonnelErrorMessage />
      <FormButton
        data-test-id={loginTestIds.formButtons.personnelContinueToLogin}
        disabled={isDisabled}
        isLoading={isLoading}
        onClick={onFetchPersonnelData}
      >
        {t('continue')}
      </FormButton>
    </Stack>
  );
};

export default ProfileWrap;
export { ProfileWrap as Profile };
