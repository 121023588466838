//@flow
import * as React from 'react';
import useFormState from './useFormState';
import usePersonnel from './usePersonnel';

import useCustomParams from './useCustomParams';

import { type UserTypeEnumType } from 'enums/UserType';
import * as qs from 'query-string';

export type LoginStatusEnumType =
  | 'dirty'
  | 'clean'
  | 'submitted'
  | 'success'
  | 'failure';
export type LoginFormStateType = {
  userId?: string,
  clientId?: string,
  password?: string,
  sanitizedUserId?: ?string,
  status?: LoginStatusEnumType,
  error: ?Error,
};
export const useLoginFormState = ({
  isAuth0Available,
  userType,
}: {
  isAuth0Available: boolean,
  userType: UserTypeEnumType,
}) => {
  const {
    personnel,
    fetchPersonnelData,
    resetPersonnel,
    error: personnelError,
    status: personnelStatus,
  } = usePersonnel({ userType });

  const { locusClientId: defaultLocusClientId } = useCustomParams();

  const {
    userId,
    sanitizedUserId,
    clientId,
    defaultClientId,
    password,
    onUserIdChange,
    onPasswordChange,
    onClientIdChange,
    onInitClientId,
    onPasswordLogin,
    onSsoLogin,
    onAuth0PlatformServiceLogin,
    error: loginError,
    status: loginStatus,
    resetLoginError,
    disableClientIdInput,
  } = useFormState({ isAuth0Available, userType });
  const redirectURI = qs.parse(window.location.search).redirect_uri;
  const { origin } = new URL(redirectURI);
  const host = location.host.split('.').slice(1).join('.'); // remove `accounts.` from host
  const { clientId: personnelClientId, personnelId } = personnel || {};
  const dashUrl = `https://${personnelClientId}.${host}/#`;
  const urlUserId = personnel ? `${personnelClientId}/${personnelId}` : null;
  const expiryUrl = urlUserId
    ? `${dashUrl}/changePassword?userId=${urlUserId}`
    : null;

  // Redirect url when platform login throws password expired error
  const passwordExpiryUrl = `${origin}/#/changePassword?userId=${clientId}/${userId}`;
  React.useEffect(() => {
    onInitClientId(defaultLocusClientId);
    //eslint-disable-next-line
  }, [defaultLocusClientId]);
  return {
    personnel,
    personnelError,
    personnelStatus,
    loginError,
    loginStatus,
    userId,
    sanitizedUserId,
    clientId,
    defaultClientId,
    password,
    disableClientIdInput,
    expiryUrl,
    passwordExpiryUrl,
    resetPersonnel: (...args) => {
      resetLoginError(...args);
      resetPersonnel(...args);
    },
    resetLoginError,
    onAuth0PlatformServiceLogin: event => {
      event.preventDefault();
      onAuth0PlatformServiceLogin();
    },
    onSsoLogin: event => {
      event.preventDefault();
      onSsoLogin(personnel.ssoDetails.connectionId);
    },
    onPasswordLogin: event => {
      event.preventDefault();
      onPasswordLogin(
        personnel.passwordAuthDetails.connectionName,
        personnel.passwordAuthDetails.identifier
      );
    },
    onFetchPersonnelData: event => {
      event.preventDefault();
      fetchPersonnelData(sanitizedUserId);
    },
    onUserIdChange: event => {
      const { value } = event.target;
      onUserIdChange(value);
    },
    onPasswordChange: event => {
      const { value } = event.target;
      onPasswordChange(value);
    },
    onClientIdChange: event => {
      const { value } = event.target;
      onClientIdChange(value);
    },
  };
};

export default useLoginFormState;
