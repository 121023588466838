//@flow

import LocusLogo from 'images/logo.png';
import LocusFavicon from 'images/favicon.ico';
import { type Provider } from './DataHelper';
export type Assets = {
  termsUrl: ?string,
  logoSrc: string,
  favicon: string,
};

export const extractAssets = (provider: ?Provider): Assets => {
  if (!provider) {
    return {
      termsUrl: 'https://locus.sh/documents/MaraLabsTermsOfUse.pdf',
      logoSrc: LocusLogo,
      favicon: LocusFavicon,
    };
  }

  const {
    largeLogoContent,
    faviconContent,
    termsAndCondtionsDocumentUrl,
  } = provider.assets;
  return {
    termsUrl: termsAndCondtionsDocumentUrl,
    logoSrc: `data:image/png;base64,${largeLogoContent}`,
    favicon: `data:image/png;base64,${faviconContent}`,
  };
};
