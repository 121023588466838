//@flow
import * as React from 'react';
import APIErrorMessage from './APIErrorMessage';
import Auth0ErrorMessage from './Auth0ErrorMessage';
import { ApiError } from 'helpers/FetchHelper';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import loginTestIds from 'components/login/loginTestIds.json';
import { Redirect } from '../core';

const LoginErrorMessage = props => {
  const { loginError, expiryUrl, passwordExpiryUrl } = useLoginFormContext();

  if (!loginError) {
    return null;
  }
  if (loginError.data?.errorCode === 1002) {
    return <Redirect to={passwordExpiryUrl} />;
  }

  if (loginError instanceof ApiError) {
    return (
      <APIErrorMessage
        error={loginError}
        testId={loginTestIds.errorMsg.loginErrorMsg}
        expiryUrl={expiryUrl}
      />
    );
  }
  return (
    <Auth0ErrorMessage
      error={loginError}
      expiryUrl={expiryUrl}
      testId={loginTestIds.errorMsg.auth0ErrorMsg}
    />
  );
};

export { LoginErrorMessage };
export default LoginErrorMessage;
