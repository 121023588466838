//@flow
import * as React from 'react';
import { Button } from '@locus-taxy/neo-ui';

const cancelFormSubmit = function (event) {
  event.preventDefault();
};
type Props = {
  onClick: () => void,
  disabled?: boolean,
  isLoading: boolean,
  children?: React.Node,
};
const FormButton = (props: Props) => {
  return (
    <Button
      {...props}
      type="submit"
      appearance="solid"
      palette="primary"
      size="large"
      onClick={props.isLoading ? cancelFormSubmit : props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};

export { FormButton };
export default FormButton;
