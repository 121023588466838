// @flow
import * as React from 'react';
import { Stack } from '@locus-taxy/neo-ui';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import { PasswordInput } from './PasswordInput';
import { useTranslation } from 'react-i18next';
import { FormButton, Hidden } from 'components/core';
import { LoginErrorMessage } from 'components/error';
import UserIdInput from 'components/login/profile/UserIdInput';

import loginTestIds from 'components/login/loginTestIds.json';
import { ForgotPassword } from './forgotPassword';

const SsoDisabled = (): React.Node => {
  const { t } = useTranslation();

  const {
    onPasswordLogin,
    password,
    loginStatus,
    personnel,
  } = useLoginFormContext();
  const isDisabled = password ? false : true;
  return (
    <Stack space="large" as="form">
      {/* Hidden user field for password manager, so that manager can save and update password for user */}
      <Hidden>
        <UserIdInput />
      </Hidden>
      <PasswordInput />
      <LoginErrorMessage />
      <FormButton
        data-test-id={loginTestIds.formButtons.loginWithPassword}
        disabled={isDisabled}
        isLoading={loginStatus === 'submitted'}
        onClick={onPasswordLogin}
      >
        {t('login')}
      </FormButton>
      <ForgotPassword
        clientId={personnel.clientId}
        personnelId={personnel.personnelId}
      />
    </Stack>
  );
};

export default SsoDisabled;
export { SsoDisabled };
