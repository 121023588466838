//@flow
import * as React from 'react';
import APIErrorMessage from './APIErrorMessage';
import Auth0ErrorMessage from './Auth0ErrorMessage';
import { ApiError } from 'helpers/FetchHelper';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import loginTestIds from 'components/login/loginTestIds.json';

const PersonnelErrorMessage = props => {
  const { personnelError, expiryUrl } = useLoginFormContext();
  if (!personnelError) {
    return null;
  }
  if (personnelError instanceof ApiError) {
    return (
      <APIErrorMessage
        error={personnelError}
        testId={loginTestIds.errorMsg.personnelErrorMsg}
      />
    );
  }
  return <Auth0ErrorMessage error={personnelError} expiryUrl={expiryUrl} />;
};

export { PersonnelErrorMessage };
export default PersonnelErrorMessage;
