// @flow
import { type UserTypeEnumType } from 'enums/UserType';

export type LoginParams = {
  identifier: string,
  connection: string,
  username: string,
  password: string,
  onError: (err: Error) => void,
};

export type SsoPlatformLoginParams = {
  username: string,
  password: string,
  onError: (err: Error) => void,
};

export type AuthorizeParams = {
  connectionId: string,
  dataClientId: string,
  userType: UserTypeEnumType,
};

export interface Auth {
  login(params: LoginParams): void;
  authorize(params: AuthorizeParams): void;
  auth0PlatformServiceLogin(params: SsoPlatformLoginParams): void;
}

export const AuthProviderEnum = Object.freeze({
  Auth0: 'auth0',
  Locus: 'locus',
  Platform: 'platform',
});

export type AuthProviderEnumType = $Values<typeof AuthProviderEnum>;
