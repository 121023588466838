// @flow
import * as React from 'react';
import { Divider, Columns, Button, Stack } from '@locus-taxy/neo-ui';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import { useTranslation } from 'react-i18next';
import { SsoDisabled } from './SsoDisabled';
import { SsoEnforced } from './SsoEnforced';
import loginTestIds from 'components/login/loginTestIds.json';

const ViewEnum = {
  OptionalSso: 'OPTIONAL_SSO',
  OptionalPassword: 'OPTIONAL_PASSWORD',
};
const DEFAULT_OPTIONAL_VIEW = ViewEnum.OptionalSso;

const SsoOptional = () => {
  const { t } = useTranslation();
  const [view, setView] = React.useState(DEFAULT_OPTIONAL_VIEW);
  const { personnel, resetLoginError } = useLoginFormContext();
  const { connectionName } = personnel.ssoDetails;

  const onToggleView = event => {
    event.preventDefault();
    resetLoginError();
    setView(view => {
      if (view === ViewEnum.OptionalSso) {
        return ViewEnum.OptionalPassword;
      }
      return ViewEnum.OptionalSso;
    });
  };
  switch (view) {
    case ViewEnum.OptionalPassword: {
      return (
        <Stack space="medium">
          <SsoDisabled />
          <Divider />
          <Columns align="center">
            <Button
              data-test-id={loginTestIds.buttons.switchToSSO}
              size="small"
              onClick={onToggleView}
              appearance="ghost"
            >
              {t('loginWithConnection', { connectionName })}{' '}
            </Button>
          </Columns>
        </Stack>
      );
    }
    case ViewEnum.OptionalSso:
    default:
      return (
        <Stack space="medium">
          <SsoEnforced />
          <Divider />
          <Columns align="center">
            <Button
              data-test-id={loginTestIds.buttons.switchToPassword}
              size="small"
              onClick={onToggleView}
              appearance="ghost"
            >
              {t('loginWithPassword')}
            </Button>
          </Columns>
        </Stack>
      );
  }
};

export default SsoOptional;
export { SsoOptional };
