// @flow
import invariant from 'invariant';

import Auth0Auth from './Auth0Auth';
import LocusAuth from './LocusAuth';
import {
  type Auth,
  AuthProviderEnum,
  type AuthProviderEnumType,
} from './types';

const createAuth = (provider: AuthProviderEnumType): Auth => {
  switch (provider) {
    case AuthProviderEnum.Auth0:
      return new Auth0Auth();
    case AuthProviderEnum.Locus:
      return new LocusAuth();
    default:
      return invariant(false, 'Invalid auth provider');
  }
};

export default createAuth;
