// @flow
import auth0 from 'auth0-js';
import { Auth, type LoginParams, type AuthorizeParams } from './types';
import EnvConfig from 'config';

import { UserTypeEnum } from 'enums/UserType';
import { checkNetwork, handleResponse } from '../helpers/FetchHelper';
import * as qs from 'query-string';

export default class Auth0Auth implements Auth {
  webAuth: auth0.WebAuth;
  _config: { redirectUri: string };

  constructor() {
    this.webAuth = new auth0.WebAuth(window.Auth0WebAuthParams);
    this._config = {
      redirectUri: qs.parse(window.location.search).redirect_uri,
    };
  }

  login({ identifier, connection, password, onError }: LoginParams) {
    this.webAuth.login(
      {
        realm: connection,
        username: identifier,
        password,
      },
      onError
    );
  }

  authorize({ connectionId, dataClientId, userType }: AuthorizeParams) {
    const loginHint =
      // NOTE: loginHint is parsed in auth0 rules, for backward compatibility with older auth0 rules
      // only adding userType in case of rider
      // This check is safe to remove in future when auth0 rules are released on all stacks
      userType === UserTypeEnum.Rider
        ? `?${dataClientId}&${userType}`
        : `?${dataClientId}`;

    this.webAuth.authorize({
      connection: connectionId,
      login_hint: loginHint,
    });
  }

  auth0PlatformServiceLogin({ username, password, onError }: LoginParams) {
    fetch(`${EnvConfig.platformEndPoint}/authenticate/usernamepassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then(res => {
        return Promise.resolve(handleResponse(res)).then(response => {
          const { accessToken: token } = response;
          const url = new URL(this._config.redirectUri);
          const searchParams = qs.parse(url.search);
          url.search = qs.stringify({ ...searchParams, token });
          window.location = url.toString();
        });
      }, checkNetwork)
      .catch(err => {
        onError(err);
      });
  }
}
