import * as React from 'react';
import { Text, Flex } from '@locus-taxy/neo-ui';
import BackgroundImage from 'images/404/404_not_found.png';

const renderErrorCode = ({ errorCodeText }) => {
  if (!errorCodeText) {
    return null;
  }
  return (
    <Text fontSize="84px" mx="auto" lineHeight={1.2}>
      {errorCodeText}
    </Text>
  );
};
const renderTitle = ({ title }) => {
  return title ? (
    <Text fontSize="48px" mx="auto" lineHeight={1.1} mb={5}>
      {title}
    </Text>
  ) : null;
};
const renderSubtitle = ({ subtitle }) => {
  return subtitle ? (
    <Text fontSize={3} mx="auto" lineHeight={1.5}>
      {subtitle}
    </Text>
  ) : null;
};

const ErrorScreen = ({
  theme,
  errorCodeText,
  title,
  subtitle,
  backgroundURL,
  renderErrorCode,
  renderTitle,
  renderSubtitle,
  children,
  ...others
}) => {
  const backgroundStyle = backgroundURL
    ? { backgroundImage: `url(${backgroundURL})` }
    : {};
  return (
    <Flex
      {...others}
      height="100%"
      width="100%"
      px={4}
      sx={{
        textAlign: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        ...backgroundStyle,
      }}
    >
      <Flex flexDirection="column" width="100%" mt={7}>
        {renderErrorCode({
          theme,
          errorCodeText,
        })}
        {renderTitle({
          title,
        })}
        {renderSubtitle({
          subtitle,
        })}
        {children}
      </Flex>
    </Flex>
  );
};
ErrorScreen.defaultProps = {
  renderTitle,
  renderSubtitle,
  renderErrorCode,
  backgroundURL: BackgroundImage,
};

export { ErrorScreen };
export default ErrorScreen;
