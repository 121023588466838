// @flow
import * as React from 'react';
import { LoginFormContext } from './LoginFormContext';
import useLoginFormState from './useLoginFormState';
import { type Assets } from 'helpers/AssetsHelper';

import { type UserTypeEnumType } from 'enums/UserType';

export const LoginFormDataProvider = ({
  isAuth0Available,
  userType,
  assets,
  children,
}: {
  isAuth0Available: boolean,
  userType: UserTypeEnumType,
  assets: Assets,
  children: React.Node,
}) => {
  const {
    personnel,
    personnelError,
    personnelStatus,
    loginError,
    loginStatus,
    userId,
    sanitizedUserId,
    clientId,
    password,
    disableClientIdInput,
    resetPersonnel,
    resetLoginError,
    onSsoLogin,
    onPasswordLogin,
    onAuth0PlatformServiceLogin,
    onFetchPersonnelData,
    onUserIdChange,
    onPasswordChange,
    onClientIdChange,
    defaultClientId,
    expiryUrl,
    passwordExpiryUrl,
  } = useLoginFormState({ isAuth0Available, userType });

  return (
    <LoginFormContext.Provider
      value={{
        personnel,
        personnelError,
        personnelStatus,
        loginError,
        loginStatus,
        userId,
        sanitizedUserId,
        defaultClientId,
        clientId,
        password,
        disableClientIdInput,
        resetPersonnel,
        resetLoginError,
        onSsoLogin,
        onPasswordLogin,
        onAuth0PlatformServiceLogin,
        onFetchPersonnelData,
        onUserIdChange,
        onPasswordChange,
        onClientIdChange,
        expiryUrl,
        passwordExpiryUrl,
        isAuth0Available,
        assets,
      }}
    >
      {children}
    </LoginFormContext.Provider>
  );
};
