// @flow
import * as React from 'react';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import { useTranslation } from 'react-i18next';
import { LoginErrorMessage, FormButton } from 'components';
import loginTestIds from 'components/login/loginTestIds.json';

const SsoEnforced = () => {
  const { onSsoLogin, loginStatus } = useLoginFormContext();
  const { t } = useTranslation();
  const { personnel } = useLoginFormContext();
  const { connectionName } = personnel?.ssoDetails || {};

  return (
    <>
      <LoginErrorMessage />
      <FormButton
        data-test-id={loginTestIds.formButtons.loginWithConnection}
        onClick={onSsoLogin}
        isLoading={loginStatus === 'submitted'}
      >
        {t('loginWithConnection', { connectionName })}
      </FormButton>
    </>
  );
};
export default SsoEnforced;
export { SsoEnforced };
