import * as React from 'react';
import ErrorScreen from './ErrorScreen';
import { useTranslation } from 'react-i18next';
import { Text } from '@locus-taxy/neo-ui';
const NotFound = () => {
  const { t } = useTranslation();
  return (
    <ErrorScreen errorCodeText="404" title={t('apologise404')}>
      <Text mb={2}>{t('subtitle404')}</Text>
      <Text>{t('subtitle404_onyx')}</Text>
    </ErrorScreen>
  );
};
export { NotFound };
export default NotFound;
