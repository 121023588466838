//@flow
import * as React from 'react';

import { Flex } from '@locus-taxy/neo-ui';
import { Logo } from 'components';
import Terms from './Terms';

export const AppHeader = (): React.Node => {
  return (
    <Flex>
      <Flex flexGrow={1}>
        <Logo />
      </Flex>
      <Flex>
        <Terms />
      </Flex>
    </Flex>
  );
};
