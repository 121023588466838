// @flow
import EnvConfig from 'config';
import { proFetch } from './FetchHelper';

import { UserTypeEnum, type UserTypeEnumType } from 'enums/UserType';
import invariant from 'invariant';

const { dashboardEndPoint, apiEndPoint, platformEndPoint } = EnvConfig;

export type Provider = {
  id: string,
  name: string,
  assets: {
    smallLogoContent: string,
    largeLogoContent: string,
    faviconContent: string,
    termsAndCondtionsDocumentUrl?: string,
  },
};

export const getLocusUserId = (clientId?: ?string, username?: ?string) => {
  if (!clientId || !username) {
    return username;
  }
  if (username.includes('/')) {
    return username;
  }
  return `${clientId}/personnel/${username}`;
};

export const formatUserId = (
  clientId?: ?string,
  username?: ?string
): ?string => {
  let formattedUsername = username;
  if (clientId && username && formattedUsername) {
    if (username.indexOf('/') < 0) {
      // if username does not contain `/`, add clientID
      formattedUsername = `${clientId}/${username}`;
    }
    // add clientId as query param too
    formattedUsername = `${formattedUsername}?${clientId}`;
  }
  // supported formats are
  // - emails: test/xyz@locus.sh?test
  // - others like test/personnel/personnelId
  return formattedUsername;
};

const parseSanitizedUserId = (
  userId: string
): { clientId: string, userId: string } => {
  const [id, clientId] = userId.split('?');
  const parts = id.split('/');

  return { clientId, userId: parts[parts.length - 1] };
};

const getPersonnelURL = (personnelId: string): string => {
  return `${dashboardEndPoint}/minimal-personnel?username=${personnelId}`;
};

const getRiderURL = (id: string): string => {
  const { clientId, userId } = parseSanitizedUserId(id);
  return `${dashboardEndPoint}/client/${clientId}/minimal-rider?username=${userId}`;
};

export const fetchPersonnel = (userId: string, userType: UserTypeEnumType) => {
  switch (userType) {
    case UserTypeEnum.Personnel:
      return proFetch.fetch(getPersonnelURL(userId));
    case UserTypeEnum.Rider:
      return proFetch.fetch(getRiderURL(userId)).then(response => {
        return {
          ...response,
          personnelId: response.riderId,
        };
      });
    default:
      invariant(false, `Invalid userType value: '${userType}' `);
  }
};

export const fetchConfig = (): Promise<{ isAuth0Available: boolean }> => {
  return proFetch.fetch(`${platformEndPoint}/config`);
};

export const requestPassworResetEmail = ({
  clientId,
  personnelId,
}: {
  clientId: string,
  personnelId: string,
}): Promise<{
  status: number,
}> => {
  return proFetch.post(
    `${apiEndPoint}/client/${clientId}/personnel-master/${personnelId}/forgot-password-request`
  );
};

export const fetchProvider = (providerId: string): Promise<Provider> => {
  return proFetch.fetch(`${platformEndPoint}/provider/${providerId}`);
};
