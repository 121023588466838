// @flow
import * as React from 'react';
import { type LoginStatusEnumType } from './useFormState';
import { type PersonnelType, type StatusEnumType } from './usePersonnel';
import { type Assets } from 'helpers/AssetsHelper';

export type LoginFormContextValueType = {
  personnel: ?PersonnelType,
  personnelError?: ?Error,
  personnelStatus?: StatusEnumType,
  loginError?: ?Error,
  loginStatus?: LoginStatusEnumType,
  userId?: string,
  defaultClientId?: string,
  clientId?: string,
  password?: string,
  sanitizedUserId?: ?string,
  expiryUrl: string,
  passwordExpiryUrl: string,
  disableClientIdInput: boolean,
  resetPersonnel: Function,
  resetLoginError: Function,
  onSsoLogin: Function,
  onPasswordLogin: Function,
  onAuth0PlatformServiceLogin: Function,
  onFetchPersonnelData: Function,
  onUserIdChange: (value: string) => void,
  onPasswordChange: (value: string) => void,
  onClientIdChange: (value: string) => void,
  isAuth0Available: boolean,
  assets: Assets,
};

export const LoginFormContext: React.Context<LoginFormContextValueType> = React.createContext(
  {}
);
