//@flow
import * as React from 'react';
import { InternetNotAvailableError, ApiError } from 'helpers/FetchHelper';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  error: typeof ApiError | typeof InternetNotAvailableError,
  testId?: string,
};
const APIErrorMessage = (props: Props) => {
  const { error, testId } = props;
  const { t } = useTranslation();
  if (!error) {
    return null;
  }
  let displayText = error.message;
  if (error instanceof InternetNotAvailableError) {
    displayText = t('internetNotAvailable');
  }
  return <ErrorMessage testId={testId}>{displayText}</ErrorMessage>;
};

export { APIErrorMessage };
export default APIErrorMessage;
