// @flow
import * as queryString from 'query-string';
import { useLocation } from '@reach/router';

import { UserTypeEnum, type UserTypeEnumType } from 'enums/UserType';
import type { LoginFlowVersionType } from './helpers';

type Params = {
  locusClientId: string,
  // (optional) - present in case of whitelabeling
  locusProviderId: ?string,
  // (optional) - can be used for idp flow
  connection: ?string,
  // userType - type of user logging in
  userType: UserTypeEnumType,
  // loginFlowVersion
  loginFlowVersion: LoginFlowVersionType,
};

const useCustomParams = (): Params => {
  const location = useLocation();
  const queryParams = location.hash
    ? // in auth0 web sdk - fragment param in sdk has special handling and
      // value is converted to generated to hash while generating URL of ULP page
      queryString.parse(location.hash)
    : // in auth0 mobile sdk - there is no special handling at sdk level and
      // fragment param is passed as it is as query param, so parsing as query param
      queryString.parse(queryString.parse(location.search).fragment);

  const {
    locusProviderId,
    locusClientId,
    connection,
    userType = UserTypeEnum.Personnel,
    loginFlowVersion,
  } = queryParams;

  return {
    locusProviderId,
    locusClientId,
    connection,
    userType: userType.toUpperCase(),
    loginFlowVersion,
  };
};

export default useCustomParams;
