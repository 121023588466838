//@flow
import * as React from 'react';
import ReactFavicon from 'react-favicon';
import { useLoginFormContext } from 'components/data/useLoginFormContext';

const Favicon = (): React.Node => {
  const { assets } = useLoginFormContext();
  return <ReactFavicon url={assets.favicon} />;
};

export default Favicon;
