// @flow
import { useQuery, type QueryResult } from 'react-query';
import { fetchProvider } from 'helpers/DataHelper';
import { extractAssets, type Assets } from 'helpers/AssetsHelper';

import { type UserTypeEnumType } from 'enums/UserType';
import useCustomParams from './useCustomParams';

export default function useInitialData(): QueryResult<{
  isAuth0Available: boolean,
  assets: Assets,
  connection: ?string,
  clientId: string,
  userType: UserTypeEnumType,
  loginFlowVersion: 'v1' | 'v2',
}> {
  const {
    locusProviderId,
    connection,
    locusClientId,
    userType,
    loginFlowVersion,
  } = useCustomParams();

  return useQuery({
    queryKey: ['initialData', locusProviderId],
    queryFn: async () => {
      const initialData = await Promise.all([
        ...(locusProviderId ? [fetchProvider(locusProviderId)] : []),
      ]);
      const [provider] = initialData;
      const assets = extractAssets(provider);
      return {
        loginFlowVersion,
        isAuth0Available: !IS_BCP_BUILD,
        assets,
        connection,
        clientId: locusClientId,
        userType,
      };
    },
    refetchOnWindowFocus: false,
  });
}
