//@flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@locus-taxy/neo-ui';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import loginTestIds from 'components/login/loginTestIds.json';

type Props = {
  type?: string,
  label?: string,
  placeholder?: string,
  autoFocus?: boolean,
};

const UserIdInput = (props: Props) => {
  const { t } = useTranslation();
  const {
    type = 'text',
    label = t('userId'),
    placeholder = t('userId'),
    autoFocus = false,
  } = props;
  const userIdInput = React.useRef<HTMLInputElement>(null);
  const { onUserIdChange, userId } = useLoginFormContext();

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const maraPersonnelId = url.searchParams.get('maraPersonnel');
    if (maraPersonnelId) {
      userIdInput.current = maraPersonnelId;
      // mimic event.target.value
      onUserIdChange({ target: { value: maraPersonnelId } });
    }

    // Not adding the dependency intentionally as `onUserIdChange` only setting the state
  }, []);

  return (
    <Input
      ref={userIdInput}
      autoFocus={autoFocus}
      size="large"
      data-test-id={loginTestIds.inputs.userId}
      type={type}
      label={label}
      placeholder={placeholder}
      onChange={onUserIdChange}
      value={userId}
      autoComplete="username"
      autoCapitalize="none"
    />
  );
};

export { UserIdInput };
export default UserIdInput;
