// @flow
import { type Auth, type LoginParams } from './types';
import { checkNetwork, handleResponse } from 'helpers/FetchHelper';
import * as qs from 'query-string';

import EnvConfig from 'config';

export default class SessionAuth implements Auth {
  _config: { redirectUri: string };

  constructor() {
    this._config = {
      redirectUri: qs.parse(window.location.search).redirect_uri,
    };
  }

  login({ username, password, onError }: LoginParams) {
    fetch(`${EnvConfig.dashboardEndPoint}/session`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then(res => {
        return Promise.resolve(handleResponse(res)).then(() => {
          const token = res.headers.get('x-auth-token');
          const url = new URL(this._config.redirectUri);
          url.search = qs.stringify({ token });
          window.location = url.toString();
          return;
        });
      }, checkNetwork)
      .catch(err => {
        onError(err);
      });
  }

  authorize() {
    throw new Error("LocusAuth doesn't support authorize flow");
  }
}
