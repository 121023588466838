// @flow
import * as React from 'react';
import { Loader } from '@locus-taxy/neo-ui';

import { createAuth, AuthProviderEnum } from 'auth';
import useMount from 'hooks/useMount';
import { type UserTypeEnumType } from 'enums/UserType';

type Props = {
  clientId: string,
  connectionId: string,
  userType: UserTypeEnumType,
};

const IdpLogin = ({ connectionId, clientId, userType }: Props) => {
  useMount(() => {
    const auth = createAuth(AuthProviderEnum.Auth0);
    auth.authorize({
      connectionId,
      dataClientId: clientId,
      userType,
    });
  });

  return <Loader size="large" width="100%" height="100%" color="blue.500" />;
};

export default IdpLogin;
