// @flow
import * as React from 'react';

type Props = { to: string };

const Redirect = ({ to }: Props) => {
  React.useEffect(() => {
    window.location = to;
  }, [to]);

  return null;
};

export default Redirect;
