//@flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { Redirect } from 'components/core';

const getNetworkStatus = error => {
  const { statusCode, code, description, message, original } = error;
  const noInternet =
    !code &&
    !statusCode &&
    !description &&
    !message &&
    !(original && original.status);
  return !noInternet;
};
const Auth0ErrorMessage = props => {
  const { t } = useTranslation();
  const { error, expiryUrl } = props;

  const { code, description, message } = error;
  const isConnected = getNetworkStatus(error);
  let displayText = description || message;
  if (!isConnected) {
    displayText = t('internetNotAvailable');
  }
  if (code === 'credentials_expired') {
    return <Redirect to={expiryUrl} />;
  }
  // NOTE: auth0 returns `password_change_required` when we login for the first time with wrong password
  // after successful login wrong password code becomes `invalid_user_password`
  // Not able to find anywhere in the auth0 docs why.
  if (code === 'invalid_user_password' || code === 'password_change_required') {
    displayText = t('wrongIdOrPassword');
  }
  if (code === 'something_went_wrong' || !displayText) {
    displayText = t('genericError');
  }
  return <ErrorMessage testId={props.testId}>{displayText}</ErrorMessage>;
};
export { Auth0ErrorMessage };
export default Auth0ErrorMessage;
