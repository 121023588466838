//@flow
import React from 'react';
import bugsnag, { Bugsnag } from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
const BUGSNAG_API_KEY = '55a00079adaeb080cb55c510da798be6';

class BugsnagHelper {
  bugsnagClient: Bugsnag.Client;
  constructor() {
    this.initialize();
  }

  initialize() {
    if (this.isBugsnagDefined() && !this.isInitialized) {
      this.bugsnagClient = bugsnag({
        apiKey: BUGSNAG_API_KEY,
      });
      this.bugsnagClient.use(bugsnagReact, React);
      this.ErrorBoundary = this.bugsnagClient.getPlugin('react');
      this.isInitialized = true;
    }
  }

  getClient() {
    return this.bugsnagClient;
  }

  isBugsnagDefined() {
    return typeof bugsnag !== 'undefined';
  }

  setUser(user) {
    if (this.isInitialized) {
      this.bugsnagClient.user = user;
    }
  }

  notify(error, { metaData, errorName, ...othersOptions } = {}) {
    const newError = new Error(error);
    if (errorName) {
      newError.name = errorName;
    }
    if (this.isInitialized) {
      this.bugsnagClient.notify(newError, {
        metaData,
        ...(othersOptions || {}),
      });
    }
  }

  getErrorBoundary() {
    return this.ErrorBoundary;
  }
}

export default new BugsnagHelper();
