// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@locus-taxy/neo-ui';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import loginTestIds from 'components/login/loginTestIds.json';

type Props = {
  type?: string,
  label?: string,
  placeholder?: ?string,
};
const PasswordInput = (props: Props) => {
  const { t } = useTranslation();
  const {
    type = 'password',
    label = t('password'),
    placeholder = t('password'),
  } = props;
  const { password, onPasswordChange } = useLoginFormContext();

  return (
    <Input
      data-test-id={loginTestIds.inputs.password}
      size="large"
      type={type}
      label={label}
      placeholder={placeholder}
      value={password}
      onChange={onPasswordChange}
      autoFocus
      autoComplete="current-password"
    />
  );
};

export { PasswordInput };
export default PasswordInput;
