//@flow

import React from 'react';
import { requestPassworResetEmail } from 'helpers/DataHelper';
import { ApiError } from 'helpers/FetchHelper';

const STATUS_RESET_TIME = 10000;

export const ForgotPasswordState = Object.freeze({
  Idle: 'forgotPassword',
  RequestingResetMail: 'requestingPasswordResetEmail',
  ResetEmailSent: 'passwordResetEmailSent',
  ResetRequestFailed: 'passwordResetEmailSendingFailed',
});

export type ForgotPasswordStateType = $Values<typeof ForgotPasswordState>;

export const useForgotPassword = ({
  clientId,
  personnelId,
}: {
  clientId: string,
  personnelId: string,
}): ({
  fpState: ForgotPasswordStateType,
  onForgotPassword: () => void,
  error: ?ApiError,
  ...
}) => {
  const [fpState, setFpState] = React.useState<ForgotPasswordStateType>(
    ForgotPasswordState.Idle
  );
  const [error, setError] = React.useState<?ApiError>();
  const resetState = React.useCallback(() => {
    setFpState(ForgotPasswordState.Idle);
    setError();
  }, []);
  React.useEffect(() => {
    const isValidPersistentState = [
      ForgotPasswordState.Idle,
      ForgotPasswordState.RequestingResetMail,
    ].includes(fpState);
    const timers = [];
    if (!isValidPersistentState) {
      // while in any other state, post 3 seconds, bring back to idle
      timers.push(setTimeout(resetState, STATUS_RESET_TIME));
    }
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [fpState, resetState]);
  const onForgotPassword = () => {
    // unless form is in Idle state, don't trigger API call
    if (fpState !== ForgotPasswordState.Idle) {
      return;
    }
    resetState();
    setFpState(ForgotPasswordState.RequestingResetMail);
    requestPassworResetEmail({ clientId, personnelId })
      .then(() => {
        setFpState(ForgotPasswordState.ResetEmailSent);
      })
      .catch(e => {
        setFpState(ForgotPasswordState.ResetRequestFailed);
        if (e instanceof ApiError) {
          setError(e);
        } else {
          throw new Error(e);
        }
      });
  };
  return {
    fpState,
    error,
    onForgotPassword,
  };
};
