import * as React from 'react';
import { Text } from '@locus-taxy/neo-ui';

const getLinkStyles = () => {
  return {
    color: 'blue.500',
    '&:hover': {
      color: 'blue.600',
    },
  };
};
const Link = props => {
  const { href, target, children, fontSize, fontWeight = 'medium' } = props;
  return (
    <Text
      as="a"
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={fontSize}
      _getBaseStyle={props.getBaseStyles || getLinkStyles}
      href={href}
      target={target}
    >
      {children}
    </Text>
  );
};

export { Link };
export default Link;
