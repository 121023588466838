//@flow
import * as React from 'react';
import { Card, Stack } from '@locus-taxy/neo-ui';
import Profile from './profile/Profile';
import LoginFragment from './fragment/LoginFragment';

const LoginForm = (): React.Node => {
  return (
    <Card>
      <Stack space="large">
        <Profile />
        <LoginFragment />
      </Stack>
    </Card>
  );
};

export { LoginForm };
export default LoginForm;
