//@flow
import * as React from 'react';
import { Text, Box } from '@locus-taxy/neo-ui';

const ErrorMessage = ({ children, testId }) => {
  return (
    <Box
      px={2}
      py={3}
      backgroundColor="red.100"
      sx={{ borderRadius: '2px' }}
      data-test-id={testId}
    >
      <Text fontSize={1} fontWeight={600} color="red.600" hyphens>
        {children}
      </Text>
    </Box>
  );
};

export { ErrorMessage };
export default ErrorMessage;
