//@flow
import * as React from 'react';
import { formatUserId, getLocusUserId } from 'helpers/DataHelper';
import { createAuth, AuthProviderEnum } from 'auth';
export type LoginStatusEnumType =
  | 'dirty'
  | 'clean'
  | 'submitted'
  | 'success'
  | 'failure';
export type FormStateType = {
  userId?: string,
  clientId?: string,
  defaultClientId?: string,
  password?: string,
  sanitizedUserId?: ?string,
  locusUserId?: ?string,
  status?: LoginStatusEnumType,
  error?: ?Error,
};

const ActionTypes = Object.freeze({
  ClientIdInit: 'CLIENT_ID_INIT',
  ClientIdChanged: 'CLIENT_ID_CHANGED',
  UserIdChanged: 'USER_ID_CHANGED',
  PasswordChanged: 'PASSWORD_CHANGED',
  LoginStart: 'LOGIN_START',
  LoginError: 'LOGIN_ERROR',
  LoginErrorReset: 'LOGIN_ERROR_RESET',
});

const formDataReducer = (state: FormStateType, action): FormStateType => {
  switch (action.type) {
    case ActionTypes.ClientIdInit: {
      const { clientId, defaultClientId } = action;
      const sanitizedUserId = formatUserId(clientId, state.userId);
      return { ...state, sanitizedUserId, clientId, defaultClientId };
    }
    case ActionTypes.ClientIdChanged: {
      const { clientId } = action;
      const sanitizedUserId = formatUserId(clientId, state.userId);
      return {
        ...state,
        sanitizedUserId,
        clientId,
        status: 'dirty',
      };
    }
    case ActionTypes.UserIdChanged: {
      const { userId } = action;
      const sanitizedUserId = formatUserId(state.clientId, userId);
      const locusUserId = getLocusUserId(state.clientId, userId);
      return {
        ...state,
        sanitizedUserId,
        locusUserId,
        userId,
        status: 'dirty',
      };
    }
    case ActionTypes.PasswordChanged: {
      const { password } = action;
      return { ...state, password, status: 'dirty' };
    }
    case ActionTypes.LoginError: {
      const { error } = action;
      return { ...state, error, status: 'failure' };
    }
    case ActionTypes.LoginStart: {
      return { ...state, status: 'submitted' };
    }
    case ActionTypes.LoginErrorReset: {
      return { ...state, status: 'clean', error: null };
    }
    default:
      return state;
  }
};

type Params = {
  isAuth0Available: boolean,
  userType: UserTypeEnumType,
};

export const useFormState = ({ isAuth0Available, userType }: Params) => {
  const auth = createAuth(
    isAuth0Available ? AuthProviderEnum.Auth0 : AuthProviderEnum.Locus
  );
  const [data, dispatch] = React.useReducer<FormStateType>(formDataReducer, {
    status: 'clean',
  });
  const onUserIdChange = (userId: string) => {
    dispatch({ type: ActionTypes.UserIdChanged, userId });
  };
  const onInitClientId = (defaultClientId: string) => {
    dispatch({
      type: ActionTypes.ClientIdInit,
      defaultClientId,
      clientId: defaultClientId,
    });
  };
  const onClientIdChange = (clientId: string) => {
    dispatch({ type: ActionTypes.ClientIdChanged, clientId });
  };
  const onPasswordChange = (password: string) => {
    dispatch({ type: ActionTypes.PasswordChanged, password });
  };
  const {
    defaultClientId,
    clientId,
    userId,
    password = '',
    sanitizedUserId,
    locusUserId,
    error,
    status,
  } = data;
  const setError = error => dispatch({ type: ActionTypes.LoginError, error });
  const setLoginStart = () => dispatch({ type: ActionTypes.LoginStart });

  const onAuth0PlatformServiceLogin = React.useCallback(() => {
    setLoginStart();
    auth.auth0PlatformServiceLogin({
      username: locusUserId,
      password,
      onError: setError,
    });
  }, [auth, locusUserId, password]);
  const onPasswordLogin = (connection: string, identifier: string) => {
    setLoginStart();
    auth.login({
      identifier,
      connection,
      username: sanitizedUserId,
      password,
      onError: setError,
    });
  };
  const onSsoLogin = (connectionId: string) => {
    setLoginStart();
    auth.authorize({
      dataClientId: clientId,
      connectionId,
      userType,
    });
  };
  const resetLoginError = () => dispatch({ type: ActionTypes.LoginErrorReset });
  return {
    userId,
    clientId,
    defaultClientId,
    password,
    sanitizedUserId,
    error,
    status,
    onUserIdChange,
    onPasswordChange,
    onClientIdChange,
    onInitClientId,
    onPasswordLogin,
    onSsoLogin,
    onAuth0PlatformServiceLogin,
    resetLoginError,
    disableClientIdInput: !!defaultClientId,
  };
};

export default useFormState;
