//@flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'components';
import { useLoginFormContext } from 'components/data/useLoginFormContext';

const Terms = (): React.Node => {
  const { t } = useTranslation();
  const { assets } = useLoginFormContext();
  return assets.termsUrl ? (
    <Link fontSize={2} target="_blank" href={assets.termsUrl}>
      {t('termsOfUse')}
    </Link>
  ) : null;
};

export default Terms;
