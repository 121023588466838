//@flow

import * as React from 'react';
import { Flex, Image } from '@locus-taxy/neo-ui';
import { useLoginFormContext } from 'components/data/useLoginFormContext';

const Logo = (): React.Node => {
  const { assets } = useLoginFormContext();
  return (
    <Flex>
      <Image
        sx={{ position: 'fixed' }}
        maxHeight="72px"
        maxWidth="180px"
        src={assets.logoSrc}
      />
    </Flex>
  );
};

export { Logo };
export default Logo;
