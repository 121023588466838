// @flow
import * as React from 'react';
import {
  LoginFormContext,
  type LoginFormContextValueType,
} from './LoginFormContext';

export const useLoginFormContext = (): LoginFormContextValueType => {
  const context = React.useContext(LoginFormContext);
  return context;
};
