// @flow
import EnvConfig from 'env-config';
import * as queryString from 'query-string';

type ConfigType = {
  apiEndPoint: string,
  dashboardEndPoint: string,
  devo?: string,
  env: 'devo' | 'prod' | 'local',
  platformEndPoint: string,
  freshdesk: {
    baseUrl: string,
    apiKey: string,
  },
};

const getEndPoints = () => {
  if (EnvConfig.env !== 'devo') {
    return EnvConfig;
  }
  // To support dynamic switching of devo env
  // What are we doing here?
  // Apps using devo ULP page can optionally pass `devo` key when calling loginWithRedirect fragment
  // and here we will switch to correct devo env based on that (by default we will use EnvConfig.devo)
  // Why?
  // Currently it's very costly to setup devo to devo-15 env on auth0, we need to setup tenants for each
  // env and then syncing everything, this is a simple way to use single auth0 devo env and
  const devo = queryString.parse(location.hash).devo || EnvConfig.devo;

  return {
    ...EnvConfig,
    dashboardEndPoint: EnvConfig.dashboardEndPoint.replace('{devo}', devo),
    apiEndPoint: EnvConfig.apiEndPoint.replace('{devo}', devo),
    platformEndPoint: EnvConfig.platformEndPoint.replace('{devo}', devo),
  };
};

const config: $Shape<ConfigType> = getEndPoints();

export default config;
