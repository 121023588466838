// @flow
import * as React from 'react';
import { Position } from '@locus-taxy/neo-ui';

type Props = { children: React.Node };

const Hidden = (props: Props) => {
  return (
    <Position position="absolute" left={99999} top={99999} zIndex={0}>
      {props.children}
    </Position>
  );
};

export default Hidden;
