// @flow
import * as React from 'react';
import {
  Button,
  Card,
  Columns,
  Divider,
  Heading,
  Input,
  Stack,
  Text,
} from '@locus-taxy/neo-ui';
import loginTestIds from './loginTestIds.json';
import { useTranslation } from 'react-i18next';
import { useLoginFormContext } from '../data/useLoginFormContext';
import FormButton from '../core/FormButton';
import useCustomParams from '../data/useCustomParams';
import { LoginErrorMessage } from '../error';
import { default as LoginFormV1 } from 'components/login/LoginForm';
import { ForgotPassword } from './fragment/forgotPassword';

export const LoginForm = (): React.Node => {
  const [singleSignOn, setSingleSignOn] = React.useState(false);
  const [maraPersonnelLogin, setMaraPersonnelLogin] = React.useState(false);
  const { t } = useTranslation();
  const {
    onUserIdChange,
    userId,
    password,
    onPasswordChange,
    loginStatus,
    onAuth0PlatformServiceLogin,
  } = useLoginFormContext();

  const { locusClientId } = useCustomParams();

  const url = new URL(window.location.href);

  const handleSingleSignOnClick = React.useCallback(() => {
    url.searchParams.set('singleSignOn', true);
    if (maraPersonnelLogin) {
      url.searchParams.set('maraPersonnel', userId);
    }
    window.location.assign(url.toString());
  }, [maraPersonnelLogin, url, userId]);

  React.useEffect(() => {
    const singleSignOn = !!url.searchParams.get('singleSignOn');
    setSingleSignOn(singleSignOn);
  }, [url]);

  const onUserIdChangeHandler = React.useCallback(
    (event: SyntheticEvent) => {
      const { value: userId } = event.target;
      if (
        userId.includes('@locus.sh') ||
        userId.startsWith('mara/personnel/')
      ) {
        setMaraPersonnelLogin(true);
      } else {
        setMaraPersonnelLogin(false);
      }
      onUserIdChange(event);
    },
    [onUserIdChange]
  );

  if (singleSignOn) {
    return <LoginFormV1 />;
  }

  return (
    <Card>
      <Stack space="large">
        <Columns space="tiny" alignY="center">
          <Heading size="xLarge">Login</Heading>
          <Divider inlineSeparator="vertical" type="inline" color="grey.200" />
          <Text color="grey.600">{locusClientId}</Text>
        </Columns>

        <Input
          type="text"
          label={t('userId')}
          placeholder={t('userId')}
          autoFocus
          size="large"
          data-test-id={loginTestIds.inputs.userId}
          onChange={onUserIdChangeHandler}
          value={userId}
          autoComplete="username"
          autoCapitalize="none"
        />

        {maraPersonnelLogin ? null : (
          <Input
            type="password"
            label={t('password')}
            placeholder={t('password')}
            size="large"
            data-test-id={loginTestIds.inputs.password}
            onChange={onPasswordChange}
            value={password}
            autoCapitalize="none"
          />
        )}

        <LoginErrorMessage />
        {maraPersonnelLogin ? null : (
          <FormButton
            data-test-id={loginTestIds.formButtons.loginWithPassword}
            isLoading={loginStatus === 'submitted'}
            onClick={onAuth0PlatformServiceLogin}
            appearance="solid"
            palette="primary"
            disabled={!userId || !password}
          >
            {t('login')}
          </FormButton>
        )}

        {loginStatus === 'submitted' ? null : (
          <Button
            icon="unlock"
            data-test-id={loginTestIds.formButtons.loginWithPassword}
            onClick={handleSingleSignOnClick}
            appearance="ghost"
            palette="primary"
          >
            Sign in with Single sign on
          </Button>
        )}

        {userId ? (
          <ForgotPassword clientId={locusClientId} personnelId={userId} />
        ) : null}
      </Stack>
    </Card>
  );
};

export default LoginForm;
