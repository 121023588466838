import './polyfill';
import 'react-hot-loader';
import { ErrorBoundary } from 'components';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@locus-taxy/neo-ui';
import App from './pages/App';
import './i18n/i18n.js';

ReactDOM.render(
  <ThemeProvider>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('app')
);
