//@flow
import * as React from 'react';
import {
  Columns,
  Column,
  Stack,
  Loader,
  Content,
  Box,
} from '@locus-taxy/neo-ui';
import { AppHeader, Favicon } from 'components';
import LoginForm from 'components/login/LoginForm';
import LoginFormV2 from 'components/login/LoginFormV2';
import FreshdeskWidget from 'components/freshdeskWidget';
import { LoginFormDataProvider } from 'components/data/LoginFormDataProvider';
import useInitialData from 'components/data/useInitialData';
import APIErrorScreen from 'components/error/APIErrorScreen';
import BackgroundImage from 'images/background.svg';
import { ApiStatusEnum } from 'enums/API';
import IdpLogin from './IdpLogin';

const Background = props => {
  return (
    <Box
      height="100%"
      width="100%"
      padding={5}
      style={{
        backgroundImage: `url(${props.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPositionY: 'bottom',
        backgroundPositionX: 'right',
      }}
    >
      {props.children}
    </Box>
  );
};

const LoginPage = (): React.Node => {
  const initialDataQuery = useInitialData();

  if (initialDataQuery.status === ApiStatusEnum.Loading) {
    return <Loader size="large" width="100%" height="100%" color="blue.500" />;
  }

  if (initialDataQuery.status === ApiStatusEnum.Error) {
    const { error, refetch } = initialDataQuery;
    return <APIErrorScreen error={error} onRetry={refetch} />;
  }

  if (initialDataQuery.data.connection) {
    return (
      <IdpLogin
        connectionId={initialDataQuery.data.connection}
        clientId={initialDataQuery.data.clientId}
        userType={initialDataQuery.data.userType}
      />
    );
  }

  return (
    <Stack height="fluid">
      <LoginFormDataProvider {...initialDataQuery.data}>
        <Favicon />
        <Background image={BackgroundImage}>
          <AppHeader />
          <Content maxWidth="960px" marginTop="204px">
            <Stack align="center" space="large">
              <Box width="100%" maxWidth="320px">
                {initialDataQuery.data.loginFlowVersion === 'v2' ? (
                  <LoginFormV2 />
                ) : (
                  <LoginForm />
                )}
              </Box>
            </Stack>
          </Content>
        </Background>
        <Box backgroundColor="black" p={4}>
          <Columns align="center">
            <Column width="3/4" />
            <Column>
              <FreshdeskWidget />
            </Column>
          </Columns>
        </Box>
      </LoginFormDataProvider>
    </Stack>
  );
};

export { LoginPage };
export default LoginPage;
