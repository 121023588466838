// @flow
import * as React from 'react';
import { Stack } from '@locus-taxy/neo-ui';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import { SsoOptional } from './SsoOptional';
import { SsoDisabled } from './SsoDisabled';
import { SsoEnforced } from './SsoEnforced';

const SsoLoginOptionsEnum = {
  Disabled: 'DISABLED',
  Enforced: 'ENFORCED',
  Optional: 'OPTIONAL',
};

const LoginFragment = () => {
  const { personnel, isAuth0Available } = useLoginFormContext();
  if (!personnel) {
    return null;
  }

  const renderFields = () => {
    const { ssoLoginOptions } = personnel.ssoDetails;

    // if Auth0 not available switch to ssoDisabled mode
    if (!isAuth0Available) {
      return <SsoDisabled />;
    }

    switch (ssoLoginOptions) {
      case SsoLoginOptionsEnum.Enforced:
        return <SsoEnforced />;
      case SsoLoginOptionsEnum.Optional:
        return <SsoOptional />;
      case SsoLoginOptionsEnum.Disabled:
      default:
        return <SsoDisabled />;
    }
  };

  return <Stack>{renderFields()}</Stack>;
};

export default LoginFragment;
