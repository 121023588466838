//@flow
import * as React from 'react';
import BugsnagHelper from 'helpers/BugsnagHelper';
import ErrorScreen from './ErrorScreen';
import { useTranslation } from 'react-i18next';
const BugsnagErrorBoundary = BugsnagHelper.getErrorBoundary();

const ErrorComponent = () => {
  const { t } = useTranslation();
  return <ErrorScreen title={t('genericError')} subtitle={t('apologise')} />;
};
const getFallbackComponent = () => () => {
  return ErrorComponent();
};
const ErrorBoundary = props => {
  return (
    <BugsnagErrorBoundary FallbackComponent={getFallbackComponent()}>
      {props.children}
    </BugsnagErrorBoundary>
  );
};

export { ErrorBoundary };
export default ErrorBoundary;
