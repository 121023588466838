//@flow

import * as React from 'react';
import { Text, Columns, Stack } from '@locus-taxy/neo-ui';
import { useTranslation } from 'react-i18next';
import {
  ForgotPasswordState,
  type ForgotPasswordStateType,
  useForgotPassword,
} from './useForgotPassword';
import { APIErrorMessage } from 'components/error/APIErrorMessage';
import testId from 'components/login/loginTestIds.json';
type Props = {
  clientId: string,
  personnelId: string,
};

const _getTextStyle = (state: ForgotPasswordStateType) => {
  switch (state) {
    case ForgotPasswordState.Idle: {
      return {
        cursor: 'pointer',
        textDecoration: 'underline',
      };
    }
  }
};

const _getTestId = (state: ForgotPasswordStateType) => {
  switch (state) {
    case ForgotPasswordState.Idle:
      return testId.formButtons.forgotPassword;
    case ForgotPasswordState.ResetEmailSent:
      return testId.successMsg.forgotPasswordSuccessMsg;
    default:
      return undefined;
  }
};

export const ForgotPassword = ({
  clientId,
  personnelId,
}: Props): React.Node => {
  const { t } = useTranslation();
  const { fpState, error, onForgotPassword } = useForgotPassword({
    clientId,
    personnelId,
  });
  return (
    <Columns align="center">
      <Stack space="large">
        <Text
          fontSize={2}
          style={_getTextStyle(fpState)}
          onClick={onForgotPassword}
          data-test-id={_getTestId(fpState)}
        >
          {t(fpState)}
        </Text>
        {fpState === ForgotPasswordState.ResetRequestFailed && error && (
          <APIErrorMessage
            error={error}
            testId={testId.errorMsg.forgotPasswordErrorMsg}
          />
        )}
      </Stack>
    </Columns>
  );
};
