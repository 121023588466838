//@flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@locus-taxy/neo-ui';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import loginTestIds from 'components/login/loginTestIds.json';
type Props = {
  label?: string,
  placeholder?: ?string,
};
const ClientIdInput = (props: Props) => {
  const { t } = useTranslation();
  const {
    type = 'text',
    label = t('clientId'),
    placeholder = t('clientId'),
  } = props;
  const {
    onClientIdChange,
    disableClientIdInput,
    clientId,
  } = useLoginFormContext();
  if (disableClientIdInput) {
    return null;
  }

  return (
    <Input
      data-test-id={loginTestIds.inputs.clientId}
      size="large"
      type={type}
      label={label}
      placeholder={placeholder}
      value={clientId}
      onChange={onClientIdChange}
    />
  );
};

export { ClientIdInput };
export default ClientIdInput;
