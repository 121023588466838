//@flow
import React from 'react';
import { GlobalStyle } from '@locus-taxy/neo-ui';
import { LoginPage } from './LoginPage';
import { NotFound } from 'components';
import { Router } from '@reach/router';
import { Global } from '@emotion/core';
import { hot } from 'react-hot-loader/root';
import { QueryClientProvider, QueryClient } from 'react-query';

const styles = {
  '#router-root': {
    height: '100%',
    width: '100%',
  },
  // Once https://locussh.atlassian.net/browse/ES-39956 is done, this can be removed.
  'body, heading': {
    fontFamily: 'Noto Sans Display, system-ui, sans-serif',
  },
};

const queryClient: QueryClient = new QueryClient();

class App extends React.PureComponent {
  render() {
    return (
      <>
        <GlobalStyle />
        <Global styles={styles} />
        <QueryClientProvider client={queryClient}>
          <Router id="router-root">
            <LoginPage path="/login" />
            <NotFound default />
          </Router>
        </QueryClientProvider>
      </>
    );
  }
}

export default hot(App);
