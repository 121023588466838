//@flow
import * as React from 'react';
import { fetchPersonnel } from 'helpers/DataHelper';
import { ApiError } from 'helpers/FetchHelper';
import {
  fetchReducer,
  initialState,
  ActionTypes,
  type StatusEnumType,
} from './helpers';

type SsoDetailsType = {
  ssoLoginOptions: 'DISABLED' | 'OPTIONAL' | 'ENFORCED',
  connectionId: string,
  connectionName: string,
};

type PasswordAuthDetailsType = {
  provider: string,
  connectionName: string,
  identifier: string,
};
export type PersonnelType = {
  clientId: string,
  personnelId: string,
  name: string,
  photoUrl: string,
  ssoDetails: SsoDetailsType,
  passwordAuthDetails: PasswordAuthDetailsType,
};
export type PersonnelDataType = {|
  personnel: ?PersonnelType,
  resetPersonnel: Event => void,
  fetchPersonnelData: (personnelId?: ?string) => void,
  status: StatusEnumType,
  error: ?Error,
|};

type Params = { userType: string };

export const usePersonnel = ({ userType }: Params): PersonnelDataType => {
  const [personnelData, dispatch] = React.useReducer(
    fetchReducer,
    initialState
  );
  const { error, status, data } = personnelData;
  const fetchPersonnelData = (personnelId: string) => {
    dispatch({ type: ActionTypes.RequestSent });
    fetchPersonnel(personnelId, userType)
      .then(data => {
        dispatch({ type: ActionTypes.RequestSuccess, data });
      })
      .catch(error => {
        const processedError = processError(error);
        dispatch({ type: ActionTypes.RequestFailure, error: processedError });
      });
  };
  const resetPersonnel = (event: Event) => {
    event.preventDefault();
    dispatch({ type: ActionTypes.ResetData });
  };

  return {
    personnel: data,
    fetchPersonnelData,
    error,
    status,
    resetPersonnel,
  };
};

export default usePersonnel;
export type { StatusEnumType };

function processError(error) {
  if (error instanceof ApiError) {
    if (error.statusCode === 404 || error.statusCode === 400) {
      return new ApiError('Invalid Client or User ID', error.statusCode);
    }
  }
  return error;
}
