//@flow
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Stack,
  Box,
  Icon,
  Text,
  Columns,
  Column,
  Button,
} from '@locus-taxy/neo-ui';

const DropState = ({ isDragActive, placeholder, dragActivePlaceholder }) => {
  return (
    <Stack align="center" space="tiny">
      <Icon icon="import" size={Icon.Size.xLarge} color="grey.300" />
      <Text fontSize={1}>
        {isDragActive ? dragActivePlaceholder : placeholder}
      </Text>
    </Stack>
  );
};

const FileCard = props => {
  return (
    <Columns alignY="center">
      <Column width="content">
        <Icon
          size={Icon.Size.xLarge}
          icon="file-empty"
          sx={{
            marginRight: '6px',
            marginTop: '5px',
          }}
          color="grey.700"
        />
      </Column>
      <Column>
        <Stack>
          <Text
            fontSize={1}
            color="grey.700"
            sx={{
              fontStyle: 'italic',
            }}
          >
            {props.file.name}
          </Text>
          <Text
            fontSize={1}
            color="grey.700"
            sx={{
              fontStyle: 'italic',
            }}
          >
            {Number(props.file.size / 1024).toFixed(2)} KB
          </Text>
        </Stack>
      </Column>
      <Column width="content">
        <Button onClick={props.onFileRemove} palette="danger">
          <Icon
            icon="cross"
            size={Icon.Size.medium}
            sx={{
              marginTop: '5px',
            }}
            color="grey.700"
          />
        </Button>
      </Column>
    </Columns>
  );
};

const Dropzone = props => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.onChange,
    disabled: props.disabled,
  });

  return (
    <>
      {props.label ? (
        <Text fontSize={2} fontWeight={600}>
          {props.label}
        </Text>
      ) : null}
      {props.files &&
        !!props.files.length &&
        props.files.map((file, index) => (
          <FileCard
            file={file}
            key={index}
            onFileRemove={() => {
              props.onFileRemove(index);
            }}
          />
        ))}
      <Box
        {...getRootProps()}
        sx={{
          background: `-webkit-linear-gradient(left,rgba(77,85,106,.1) 70%,transparent 0),-webkit-linear-gradient(left,rgba(77,85,106,.1) 70%,transparent 0),-webkit-linear-gradient(top,rgba(77,85,106,.1) 70%,transparent 0),-webkit-linear-gradient(top,rgba(77,85,106,.1) 70%,transparent 0)`,
          backgroundSize: '.9rem .1rem,.9rem .1rem,.1rem .9rem,.1rem .9rem',
          backgroundRepeat: 'repeat-x,repeat-x,repeat-y,repeat-y',
          backgroundPosition: '0 0,0 100%,0 0,right 0',
          padding: '20px 0',
          '&:hover': !props.disabled && {
            backgroundColor: 'blue.100',
          },
        }}
      >
        <input {...getInputProps()} />
        <DropState
          isDragActive={isDragActive}
          placeholder={props.placeholder}
          dragActivePlaceholder={props.dragActivePlaceholder}
        />
      </Box>
    </>
  );
};

export default Dropzone;
