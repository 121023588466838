//@flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  Heading,
  Button,
  Stack,
  Input,
  Icon,
  Text,
} from '@locus-taxy/neo-ui';
import ErrorMessage from 'components/error/ErrorMessage';
import Dropzone from 'components/neo/Dropzone';
import useFreshdeskState from 'components/data/useFreshdeskState';

const LinkButton = ({ onDialogOpen }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        lineHeight: '18px',
        border: 0,
        fontSize: 14,
        outline: 'none',
        color: 'grey.200',
        backgroundColor: 'transparent',
        fontWeight: 600,
        textDecoration: 'underline',
        '&:hover': {
          color: 'white',
          textDecoration: 'underline',
        },
      }}
      as="button"
      onClick={onDialogOpen}
    >
      {t('support')}
    </Box>
  );
};

const FreshdeskWidget = () => {
  const { t } = useTranslation();
  const form = React.useMemo(
    () => ({
      name: {
        touched: false,
        fieldId: 'name',
        label: t('name'),
        placeholder: t('enterYourName'),
        value: '',
        type: 'text',
        required: true,
      },
      email: {
        touched: false,
        fieldId: 'email',
        label: t('emailId'),
        placeholder: t('enterYourEmail'),
        value: '',
        type: 'email',
        required: true,
      },
      ccEmails: {
        touched: false,
        fieldId: 'ccEmails',
        label: t('cc'),
        placeholder: t('enterCC'),
        value: '',
        type: 'text',
        pattern: '\\w+@\\w+\\.\\w+(,s*\\w+@\\w+\\.\\w+)*',
        patternFlags: 'gi',
        errorMsg: {
          pattern: 'Please Enter Comma separated e-mails',
        },
        required: false,
      },
      phone: {
        touched: false,
        fieldId: 'phone',
        label: t('contactNumber'),
        placeholder: t('enterYourPhone'),
        value: '',
        type: 'text',
        required: false,
      },
      subject: {
        touched: false,
        fieldId: 'subject',
        label: t('subject'),
        placeholder: t('enterSubject'),
        value: '',
        type: 'text',
        required: true,
      },
      message: {
        touched: false,
        fieldId: 'message',
        label: t('message'),
        placeholder: t('explainIssue'),
        value: '',
        as: 'textarea',
        required: true,
        rows: 4,
      },
      attachments: {
        touched: false,
        fieldId: 'attachments',
        label: t('attachmentsIfAny'),
        placeholder: t('clickToUpload'),
        dragActivePlaceholder: t('clickToUploadActive'),
        files: [],
        onRemoveFile: 'onRemoveFile',
        type: 'file',
        value: [],
      },
    }),
    [t]
  );

  const {
    isFormVisible,
    toggleFormVisibility,
    handleFormSubmit,
    formPostStatus,
    clearErrorState,
    formState,
    onBlur,
    errorState,
    inputOnChange,
    onDropFile,
    onFileRemove,
  } = useFreshdeskState({ form });

  return (
    <>
      <LinkButton onDialogOpen={toggleFormVisibility} />
      <Dialog
        isOpen={isFormVisible}
        sticky
        dismissOnBackdrop={false}
        dismissOnEscape={false}
      >
        <DialogHeader>
          <Heading level="2" textTransform="uppercase">
            {t('contactUs')}
          </Heading>
        </DialogHeader>
        <DialogContent
          style={{
            minHeight: '20vh',
          }}
        >
          <Body
            formState={formState}
            errorState={errorState}
            formPostStatus={formPostStatus}
            onBlur={onBlur}
            inputOnChange={inputOnChange}
            onDropFile={onDropFile}
            onFileRemove={onFileRemove}
          />
        </DialogContent>
        <DialogFooter>
          <Button
            appearance="outline"
            palette="neutral"
            onClick={toggleFormVisibility}
            disabled={formPostStatus.isProcessing}
          >
            {formPostStatus.success ? t('close') : t('cancel')}
          </Button>
          {formPostStatus.error ? (
            <Button
              appearance="solid"
              palette="primary"
              onClick={clearErrorState}
            >
              {t('retry')}
            </Button>
          ) : (
            !formPostStatus.success && (
              <Button
                disabled={formPostStatus.isProcessing}
                appearance="solid"
                palette="primary"
                onClick={handleFormSubmit}
                isLoading={formPostStatus.isProcessing}
              >
                {t('save')}
              </Button>
            )
          )}
        </DialogFooter>
      </Dialog>
    </>
  );
};

const Body = ({
  errorState,
  formState,
  onBlur,
  inputOnChange,
  onDropFile,
  onFileRemove,
  formPostStatus: { success, error, isProcessing },
}) => {
  const { t } = useTranslation();

  if (success) {
    return (
      <Stack space="xxSmall" align="center">
        <Icon
          icon="check"
          sx={{
            fontSize: '48px',
          }}
          color="green.500"
        />
        <Text>{t('thanksForReachingOut')}</Text>
        <Text>
          {t('successRequestSubmitted', { tickedId: success.tickedId })}
        </Text>

        <Text>{t('successTrackingEmail')}</Text>
      </Stack>
    );
  }

  if (error) {
    return (
      <Stack space="xxSmall" align="center">
        <Text fontSize={4} textTransform="uppercase">
          {t('errorLabel')}
        </Text>
        {error.message && <Text>{error.message}</Text>}
      </Stack>
    );
  }

  return (
    <Stack space="medium">
      {Object.values(formState).map(field => {
        const label = field.required ? `${field.label} *` : field.label;
        return (
          <React.Fragment key={field.fieldId}>
            {field.type === 'file' ? (
              <Dropzone
                {...field}
                label={label}
                onChange={files => onDropFile(files, field)}
                onFileRemove={index => onFileRemove(index, field)}
                disabled={isProcessing}
              />
            ) : (
              <Input
                {...field}
                disabled={isProcessing}
                label={label}
                sx={
                  field.as === 'textarea'
                    ? {
                        paddingY: 2,
                      }
                    : {}
                }
                onBlur={event => onBlur(event, field)}
                onChange={event => inputOnChange(event, field)}
              />
            )}

            {field.touched && errorState[field.fieldId] && (
              <ErrorMessage>{errorState[field.fieldId]}</ErrorMessage>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default FreshdeskWidget;
