// @flow

export const ActionTypes = Object.freeze({
  RequestSent: 'REQUEST_SENT',
  RequestSuccess: 'REQUEST_SUCCESS',
  RequestFailure: 'REQUEST_FAILURE',
  ResetData: 'DATA_RESET',
});

export type StatusEnumType = 'idle' | 'submitted' | 'success' | 'failure';

export type LoginFlowVersionType = 'v1' | 'v2';

export const initialState = { status: 'idle', data: null, error: null };

export type StateType<TData> = {
  data: TData | null,
  status: StatusEnumType,
  error: Error | null,
};

export const fetchReducer = <TData>(
  state: StateType<TData>,
  action
): StateType<TData> => {
  switch (action.type) {
    case ActionTypes.RequestSent:
      return { ...state, status: 'submitted', data: null, error: null };
    case ActionTypes.RequestSuccess:
      return {
        ...state,
        status: 'success',
        data: action.data,
        error: null,
      };

    case ActionTypes.RequestFailure:
      return {
        ...state,
        status: 'failure',
        data: null,
        error: action.error,
      };
    case ActionTypes.ResetData:
      return {
        ...state,
        status: 'idle',
        data: null,
        error: null,
      };
    default:
      return initialState;
  }
};
