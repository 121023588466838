//@flow
import * as React from 'react';
import { serialize } from 'object-to-formdata';
import EnvConfig from 'config';

import { proFetch } from 'helpers/FetchHelper';
import { useLoginFormContext } from 'components/data/useLoginFormContext';
import i18n from 'i18n/i18n';

const ACTION_TYPES = Object.freeze({
  RESET_STATE: 'RESET_STATE',
  UPDATE_ERRORS: 'UPDATE_ERRORS',
  UPDATE_VALUE: 'UPDATE_VALUE',
  ADD_FILES: 'ADD_FILES',
  REMOVE_FILE: 'REMOVE_FILE',
  MARK_FIELD_TOUCHED: 'MARK,_FIELD_TOUCHED',
  SET_ALL_VALIDATION_ERRORS: 'SET_ALL_VALIDATION_ERRORS',
  MARK_ALL_FIELD_TOUCHED: 'MARK_ALL_FIELD_TOUCHED',
});

const formReducer = (state, action) => {
  const { type, value, fieldProps = {}, files, fileIndex } = action;
  const { fieldId } = fieldProps;
  switch (type) {
    case ACTION_TYPES.UPDATE_VALUE:
      return {
        ...state,
        [fieldId]: {
          ...state[fieldId],
          value,
        },
      };

    case ACTION_TYPES.ADD_FILES:
      return {
        ...state,
        [fieldId]: {
          ...state[fieldId],
          files: [...state[fieldId].files, ...files],
        },
      };

    case ACTION_TYPES.REMOVE_FILE: {
      const nextFiles = [...state[fieldId].files];
      nextFiles.splice(fileIndex, 1);
      return {
        ...state,
        [fieldId]: {
          ...state[fieldId],
          files: nextFiles,
        },
      };
    }

    case ACTION_TYPES.MARK_FIELD_TOUCHED: {
      return {
        ...state,
        [fieldId]: {
          ...state[fieldId],
          touched: true,
        },
      };
    }

    case ACTION_TYPES.MARK_ALL_FIELD_TOUCHED: {
      const nextState = {};
      Object.values(state).forEach(field => {
        nextState[field.fieldId] = {
          ...field,
          touched: true,
        };
      });
      return nextState;
    }

    case ACTION_TYPES.RESET_STATE: {
      return { ...action.initialState };
    }

    default:
      throw new Error(`Invalid type passed as ${type} for fieldId ${fieldId}`);
  }
};

const getValidationErrorMsg = ({ fieldProps, value }) => {
  if (fieldProps.required && !value) {
    return (
      (fieldProps.errorMsg && fieldProps.errorMsg.required) ||
      i18n.t('fieldRequired')
    );
  } else if (fieldProps.pattern && value) {
    const regEx = new RegExp(fieldProps.pattern, fieldProps.patternFlags);
    if (!regEx.test(value)) {
      return (
        (fieldProps.errorMsg && fieldProps.errorMsg.pattern) ||
        i18n.t('invalidValues')
      );
    }
  } else if (fieldProps.type === 'email' && value) {
    const emailRegEx = new RegExp('\\w+@\\w+\\.\\w+');
    if (!emailRegEx.test(value)) {
      return (
        (fieldProps.errorMsg && fieldProps.errorMsg.email) ||
        i18n.t('invalidEmail')
      );
    }
  }
};

const errorReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_ERRORS: {
      const { fieldId } = action.fieldProps;
      const validationMsg = getValidationErrorMsg(action);
      if (validationMsg) {
        //add error msg
        return { ...state, [fieldId]: validationMsg };
      } else if (state[fieldId]) {
        //remove error msg
        const nextState = { ...state };
        delete nextState[fieldId];
        return nextState;
      }
      return state;
    }

    case ACTION_TYPES.SET_ALL_VALIDATION_ERRORS:
      return action.errors;

    case ACTION_TYPES.RESET_STATE: {
      return { ...action.initialState };
    }

    default:
      throw new Error(
        `Invalid type passed as ${action.type} for fieldId ${fieldId}`
      );
  }
};

const useFreshdeskState = ({ form }) => {
  const formPostStatusInitialState = React.useMemo(
    () => ({
      isProcessing: false,
      success: null,
      error: null,
    }),
    []
  );

  const [formState, setFormState] = React.useReducer(formReducer, form);

  const [errorState, setErrorState] = React.useReducer(errorReducer, {});

  const [formPostStatus, setFormPostStatus] = React.useState({
    ...formPostStatusInitialState,
  });
  const [isFormVisible, setIsFormVisible] = React.useState(false);

  const isMounted = React.useRef(false);

  const { clientId } = useLoginFormContext();

  const postFormData = React.useCallback(
    attachments => {
      const {
        name: { value: name },
        email: { value: email },
        ccEmails: { value: ccEmails },
        subject: { value: subject },
        message: { value: message },
      } = formState;

      const requestJSON = {
        name,
        email,
        subject,
        priority: 1,
        status: 2,
        description: `${message} \n --- \n ${
          clientId ? `Client Id: ${clientId}` : ''
        }`,
      };

      if (ccEmails.length) {
        requestJSON.cc_emails = ccEmails.split(', ');
      }
      if (attachments.length) {
        requestJSON.attachments = attachments;
      }

      const { baseUrl, apiKey } = EnvConfig.freshdesk;

      const requestData = requestJSON.attachments
        ? serialize(requestJSON)
        : JSON.stringify(requestJSON);

      const url = `${baseUrl}/api/v2/tickets/`;

      const customHeaders = {
        Authorization: `Basic ${btoa(`${apiKey}:x`)}`,
      };

      if (!requestJSON.attachments) {
        customHeaders['Content-Type'] = 'application/json';
      }

      const headers = new Headers(customHeaders);

      proFetch
        .post(url, requestData, headers)
        .then(res => {
          setFormPostStatus({
            isProcessing: false,
            success: { tickedId: res.id },
          });
        })
        .catch(err => {
          setFormPostStatus({
            isProcessing: false,
            error: { message: err.description },
          });
        });
    },
    [formState, clientId]
  );

  const handleFormSubmit = React.useCallback(async () => {
    const fieldsValueList = Object.values(formState);
    const errors = {};
    let hasError = false;
    let markAllFieldTouched = false;
    fieldsValueList
      .filter(({ required }) => required)
      .forEach(field => {
        const errorMsg = getValidationErrorMsg({
          fieldProps: field,
          value: field.value,
        });
        if (errorMsg) {
          hasError = true;
          errors[field.fieldId] = errorMsg;
          if (!field.touched) {
            markAllFieldTouched = true;
          }
        }
      });

    if (hasError) {
      setErrorState({
        type: ACTION_TYPES.SET_ALL_VALIDATION_ERRORS,
        errors,
      });
      if (markAllFieldTouched) {
        setFormState({
          type: ACTION_TYPES.MARK_ALL_FIELD_TOUCHED,
        });
      }
      return;
    }

    setFormPostStatus({
      ...formPostStatus,
      isProcessing: true,
    });

    postFormData(formState.attachments.files);
  }, [formPostStatus, postFormData, formState]);

  const resetAllStates = React.useCallback(() => {
    setFormState({
      type: ACTION_TYPES.RESET_STATE,
      initialState: form,
    });
    setErrorState({
      type: ACTION_TYPES.RESET_STATE,
      initialState: {},
    });
    setFormPostStatus({ ...formPostStatusInitialState });
  }, [formPostStatusInitialState, form]);

  React.useEffect(() => {
    if (isMounted.current) {
      if (!isFormVisible) {
        resetAllStates();
      }
    } else {
      isMounted.current = true;
    }
  }, [isMounted, isFormVisible, resetAllStates]);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const clearErrorState = () => {
    setFormPostStatus({
      ...formPostStatus,
      error: null,
    });
  };

  const onBlur = React.useCallback((event, field) => {
    setFormState({
      value: event.target.value,
      fieldProps: field,
      type: ACTION_TYPES.MARK_FIELD_TOUCHED,
    });
    setErrorState({
      type: ACTION_TYPES.UPDATE_ERRORS,
      value: event.target.value,
      fieldProps: field,
    });
  }, []);

  const inputOnChange = React.useCallback((event, field) => {
    setFormState({
      type: ACTION_TYPES.UPDATE_VALUE,
      value: event.target.value,
      fieldProps: field,
    });
    setErrorState({
      type: ACTION_TYPES.UPDATE_ERRORS,
      value: event.target.value,
      fieldProps: field,
    });
  }, []);

  const onDropFile = React.useCallback((files, field) => {
    setFormState({
      type: ACTION_TYPES.ADD_FILES,
      files,
      fieldProps: field,
    });
  }, []);

  const onFileRemove = React.useCallback((index, field) => {
    setFormState({
      type: ACTION_TYPES.REMOVE_FILE,
      fileIndex: index,
      fieldProps: field,
    });
  }, []);

  return {
    isFormVisible,
    formPostStatus,
    formState,
    errorState,
    toggleFormVisibility,
    setErrorState,
    handleFormSubmit,
    clearErrorState,
    onBlur,
    inputOnChange,
    onDropFile,
    onFileRemove,
  };
};

export default useFreshdeskState;
