// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@locus-taxy/neo-ui';

import { InternetNotAvailableError } from 'helpers/FetchHelper';
import ErrorScreen from './ErrorScreen';

type Props = {
  error: Error,
  onRetry: () => void,
};

const APIErrorScreen = ({ error, onRetry }: Props): React.Node => {
  const { t } = useTranslation();
  const message =
    error instanceof InternetNotAvailableError
      ? t('internetNotAvailable')
      : t('genericError');

  return (
    <ErrorScreen title={message}>
      <Flex justifyContent="center">
        <Button appearance="solid" palette="primary" onClick={onRetry}>
          {t('retry')}
        </Button>
      </Flex>
    </ErrorScreen>
  );
};

export default APIErrorScreen;
